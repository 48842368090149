@import '../../variables.scss';

.menuWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 5%);

    .eaWrapper {
        width: 100%;
        background: #ffffff;
        border-bottom: 1px solid #eee;
    }

    .ea {
        padding: 8px 0 8px 245px;

        &__zoomItem {
            @apply py-1 px-2 border hover:text-gray transition border-transparent;
        }

        &__zoomItem--active {
            @apply py-1 px-2 border border-blue rounded text-blue rounded-md;
        }

        .ant-switch {
            &[aria-checked="false"] {
                background: #d7d7d7;

                &:hover {
                    background: #c0c0c0;
                }
            }
        }
    }
}

.subMenuOverlay {
    cursor: pointer;
    // background: #ffffff51;
    background-color: rgba(0, 0, 0, 0.45);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
}

.subMenuWrapper {
    position: relative;
    height: 100vh;
    transition: $transition;
    z-index: -1;
}

.subMenu {
    z-index: 2;
    position: relative;
    background: #fff;
    font-size: 16px;
    border-bottom: 1px solid #eee;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    top: 0px;
    overflow: hidden;
    animation: slideDown .2s ease-in-out;

    &__itemsMain {
        display: flex;
        flex: none;
        padding: 15px 0;
        width: 230px !important;
        flex-direction: column;
        border-right: 1px solid #eee;
    }

    &__items {
        // border: 1px solid green;
        display: flex;
        grid-gap: 5px;
        flex-wrap: wrap;
        margin-top: 9px;
        align-items: center;
    }

    &__item {
        white-space: nowrap;
        cursor: pointer;
        padding: 7px 20px;
        min-width: 200px;
        transition: $transition;

        // border: 1px solid red;

        &:hover {
            color: $blue;
        }
    }

    &__item--active {
        padding: 7px 20px;
        min-width: 200px;
        background: #36aae018;
        color: $blue;
    }

    &__childItem {
        // white-space: nowrap;
        line-height: 18px;
        cursor: pointer;
        padding: 7px 15px;
        // min-width: 200px;
        width: 250px;
        transition: $transition;

        // border: 1px solid red;

        &:hover {
            color: $blue;
        }
    }

    &__childItem--active {
        padding: 7px 15px;
        // min-width: 200px;
        width: 250px;
        background: #36aae018;
        color: $blue;
        // border: 1px solid teal;

    }
}

.menu {
    font-family: 'Roboto Condensed', sans-serif;
    background: #fff;
    transition: $transition;
    border-bottom: 1px solid #eee;

    &__logoWrapper {
        border-right: 1px solid #eee;
        padding: 10px 20px 10px 0;
        flex: none;
        width: 230px;

        img {
            width: 70px;
        }
    }

    &__items {
        width: 100%;

        .ant-menu-item,
        .ant-menu-title-content {
            color: #152A37;
            font-size: 16px;
        }

        .ant-menu-item-active span, .ant-menu-item-selected span {
            color: $blue;

            path {
                transition: $transition;
                fill: $blue;
            }
        }
        

        .ant-menu-item:has(> span div.menu__searchMenuItem) {
            margin-left: auto;
            margin-right: 10px;
        }

        &>ul {
            background: transparent;
            font-family: 'Roboto Condensed', sans-serif;
            height: 100%;
            height: 100%;
            border-bottom: none;
            padding-top: 2px;
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__item {
        font-size: 17px;
        font-weight: 300;
    }

    &__eaBtnWrapper {
        width: 130px;
        cursor: pointer;
        font-family: 'Gotham';
        font-weight: 500;
        line-height: 14px;
        font-size: 13px;
        transition: $transition;

        &:hover {
            color: $blue;
        }

        &:hover .menu__eaBtn {
            box-shadow: 0px 0px 5px 3px lighten($color: $blue, $amount: 35) !important;
        }
    }

    &__eaBtn {
        transition: $transition;
        border-radius: 8px;
        border: 1px solid transparent;
    }
    
}


.is-sticky {
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    // background-color: #ffffffeb;
    // backdrop-filter: blur(2px);
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    transition: $transition;
}

.is-sticky--light {
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: #ffffff80;
    backdrop-filter: blur(2px);
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding: 10px 0;
    transition: $transition;
}


@keyframes slideDown {
    0% {
        top: -20px;
        opacity: 0;
    }

    100% {
        top: 0px;
        opacity: 1;
    }
}

@media (max-width: 1100px) {

    .menuWrapper {
        .ea {
            padding: 8px 0;
        }
    }
}