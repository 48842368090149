@font-face {
  font-family: 'Gotham';
  font-display: swap;
  src: local('Gotham'),
   url('./fonts/gotham/Gotham-Book.otf') format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-display: swap;
    font-weight: 500;
    src: local('Gotham'), url('./fonts/gotham/Gotham-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-display: swap;
  font-weight: 600;
  src: local('Gotham'), url('./fonts/gotham/Gotham-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-display: swap;
    font-weight: 700;
    src: local('Gotham'), url('./fonts/gotham/Gotham-Black.otf') format('opentype');
}

body {
  /* font-family: Gotham; */
  font-family: 'Roboto Condensed', sans-serif;
  font-display: swap;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #152A37;
  background: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.highlight {
  background-color: yellow; /* Change this to whatever highlighting style you want */
}

@tailwind base;
@tailwind components;
@tailwind utilities;