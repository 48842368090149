@import '../../variables.scss';

.ant-input.ant-input-outlined:focus-within {
    backdrop: #fff !important;
}
.search {
    display: flex;
    flex-direction: column;
    

    &__resultItems {
        overflow-y: auto;
        display: block;
        max-height: 500px;
        position: relative;
        margin-top: 20px !important;
        padding-right: 10px;

        // &::after {
        //     content: "";
        //     position: sticky;
        //     bottom: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 50px;
        //     background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
        // }

        &::-webkit-scrollbar-track {
            background-color: lighten($color: $blue, $amount: 40) !important;
            border-radius: 5px;
        }

        &::-webkit-scrollbar {
            width: 5px !important;
            background-color: lighten($color: $blue, $amount: 40) !important;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $blue !important;
            border-radius: 5px;
            cursor: pointer !important;
        }

    }
        &__resultItem {
        transition: $transition;
        
        &:hover .search__resultTitle {
            color: $blue;
        }

        &:hover {
            background: lighten($color: $blue, $amount: 43);
        }
    }

    &__resultTitle {
        transition: $transition;
    }
}

@media (max-width: 640px) {
    .search {
        height: 400px;
    }

}