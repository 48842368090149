@import '../../variables.scss';

.mobileMenuWrapper {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0 auto;
  padding: 10px 20px;
  z-index: 101;
}

.mobileMenu {
  margin: 0 auto;
  padding: 0 20px;
  background: #fff;
  z-index: 100 !important;
  box-shadow: #152a3715 0px 2px 8px 0px;
}

#nav-container {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 100;
  pointer-events: none;
  top: 0;
}

#nav-container .bg {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: calc(100% - 50px);
  visibility: hidden;
  opacity: 0;
  transition: .3s;
  background: #ffffff;
  pointer-events: none;
}

#nav-container:focus-within .bg {
  visibility: visible;
  opacity: .6;
}

#nav-container * {
  visibility: visible;
}

.button {
  margin-left: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 20;
  -webkit-appearance: none;
  border: 0;
  background: transparent;
  border-radius: 0;
  height: 50px;
  width: 26px;
  cursor: pointer;
  pointer-events: auto;
  touch-action: manipulation;
  -webkit-tap-highlight-color: rgba(224, 224, 224, 0);
}

.icon-bar {
  display: block;
  border-radius: 5px;
  width: 100%;
  height: 3px;
  background: #36a9e0;
  transition: .3s;
}

.icon-bar+.icon-bar {
  margin-top: 5px;
}

#nav-container:focus-within .button {
  pointer-events: none;
}

#nav-container:focus-within .icon-bar:nth-of-type(1) {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

#nav-container:focus-within .icon-bar:nth-of-type(2) {
  opacity: 0;
}

#nav-container:focus-within .icon-bar:nth-of-type(3) {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

#nav-content {
  margin-top: 50px;
  padding: 20px 0;
  width: 90%;
  max-width: 350px;
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100% - 50px);
  overflow-y: scroll;
  color: #0E2A38;
  z-index: 2000 !important;
  background: #ffffff;
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateX(100%);
  transition: transform .3s;
  will-change: transform;
  contain: paint;

  .mobileMenuItemWrapper {
    width: 100%;
    border-bottom: 1px solid #eee;
  }

  .mobileMenuItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    font-weight: 300;

    img {
      width: 10px;
    }
  }

  .mobileMenuSubitem {
    margin-top: 20px;
  }
}

#nav-container:focus-within #nav-content {
  transform: none;
}