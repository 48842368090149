@import '../../variables.scss';

.footer {
  background: $darkBlue;
  color: #fff;

  &__link {
    transition: $transition;
    border-bottom: 1px solid transparent;
    width: fit-content;
  
    &:hover {
      border-bottom: 1px solid #ffffff92;
    }
  }

  &__socialIcon {
    cursor: pointer;
    transition: $transition;
    border: 1px solid transparent;
    padding: 5px;
    border-radius: 5px;

    &:hover {
      border: 1px solid #ffffff5f;
    }
  }
}

.knowledgeHubPost {
  &__socialIcon {
    cursor: pointer;
    transition: $transition;
    border: 1px solid transparent;
    padding: 5px;
    border-radius: 5px;

    &:hover {
      border: 1px solid lighten($color: $blue, $amount: 30);
    }
  }
}