@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap');
@import './variables.scss';

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .aa {
    font-size: 40px;
    text-align: right;
  }
}

button, .speaker, img {
  &:focus-visible {
    outline: 4px solid $darkBlue !important;
  }
}

a {
  &:focus-visible {
    outline: 4px solid $blue !important;
  }
}

.container {
  max-width: 1600px;
  width: 90%;
  margin: 0 auto;
}

.sidebar {
  position: sticky;
  font-weight: 400;
  top: 120px;
  height: fit-content;
  background: #ffffff;
  z-index: 2;
  display: flex;
  max-width: 250px;

  &>div {
    min-width: 180px;
    flex: 0 0 auto;
  }
}

.ant-anchor-link {
  cursor: pointer;
}

.sidebar a {
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
}

.pageTitle {
  font-size: 36px;
  line-height: 40px;
  margin: 15px 0 20px 0;
}

.infoPage {
  font-weight: 300;
  font-size: 16px;
  animation: fadeIn .5s ease-in-out;

  .text {
    margin-top: 12px;
  }

  .subtitle {
    font-size: 22px;
    margin-top: 25px;
    font-style: italic;
  }

  .sliderItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 140px;
    height: 160px;
    grid-gap: 15px;
    border-radius: 20px;
    padding: 15px;
    cursor: pointer;
    justify-content: center;
    box-shadow: 0px 20px 50px 0px rgba(1, 23, 54, 0.073);
    border: 1px solid transparent;
    transition: $transition;
    line-height: 18px;

    &:hover {
      border: 1px solid lighten($blue, 30);
    }
  }

  .sliderItem--active {
    @extend .sliderItem;
    background: rgba(239, 247, 252, 1);
    color: $blue;
  }
}

.policiesPage {
  font-weight: 300;

  .sidebar {
    height: 80vh;
    min-width: 300px;
    overflow-y: scroll !important;
  }

  ul {
    margin-top: 5px;
    li {
      &::before {
        content: '—';
        margin-right: 5px;
        color: $blue;
      }
    }  
  }

  ol{
    margin-top: 5px;
    list-style: none;
    counter-reset: item;
  }
  
  ol > li:before{
      counter-increment: item;
      content: counter(item, lower-alpha)")";
      color: $blue;
      margin-right: 5px;
  }

  // p {
  //   margin-top: 12px;
  // }

  .text {
    margin-top: 12px;
  }

  .subtitle {
    font-size: 22px;
    margin-top: 25px;
    color: $blue;
  }

  .pageTitle {
    font-size: 36px;
    line-height: 40px;
    margin: 15px 0 20px 0;
  }
}

// POPUP

.popup,
.conatctUs,
.search {
  font-family: 'Roboto Condensed', sans-serif;

  .ant-form-item {
    margin-bottom: 10px;
  }

  .ant-form-item-label {
    padding-bottom: 5px;
  }

  .ant-form-item-required::before {
    content: "" !important;
  }

  .ant-form-item-required::after {
    color: #ff4d4f;
    content: "*" !important;
    visibility: visible !important;
  }

  .ant-input-status-error:not(.ant-input-disabled) {
    background: #fff !important;
  }

  .ant-input-textarea-affix-wrapper.ant-input-affix-wrapper {
    background: #fff;
  }

  .ant-input-textarea-show-count .ant-input-data-count {
    top: -25px;
    font-size: 12px;
  }

  input {
    background: #fff;
    // border: none;
    // border-bottom: 1.5px solid #eee;

    &:hover {
      background: #fff;
      // border-bottom: 1.5px solid $blue;
    }

    &:focus {
      background: #fff;
      // box-shadow: none;
    }
  }
}

//BOOK A CONSULTATION BUTTON 

.bookConsultationBtn {
  position: fixed;
  bottom: 130px;
  right: -85px;
  cursor: pointer;
  width: 150px;
  z-index: 5;
  cursor: pointer;
}

.fadeIn {
  animation: fadeIn .5s ease-in-out;
}

.fadeInFast {
  animation: fadeIn .1s ease-in-out;
}

.animate-slideLeft {
  animation: slideLeft 0.5s ease-in-out forwards;
}


// NUMBERS SECTION 
.numberItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .numberItem {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1;
    min-width: 300px;
    max-width: 420px;
    position: relative;
    z-index: -10;

    &:nth-child(2) .number {
      animation-delay: .3s;
    }

    &:nth-child(3) .number {
      animation-delay: .5s;
    }

    &:nth-child(4) .number {
      animation-delay: .7s;
    }

    &:nth-child(5) .number {
      animation-delay: .9s;
    }
  }

  .number {
    @apply text-lightBlue font-bold;
    font-size: 190px;
    line-height: 180px;
    animation: slideInLeft .5s forwards;
    animation-delay: .1s;
    opacity: 0;
  }

  .numberItemContent {
    z-index: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
  }
}

// TIMELINE SECTION 
.timeline {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 3px;
    width: 100%;
    border-radius: 5px;
    background: #F3F3F3;
  }

  &__item {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2.5px solid #F3F3F3;
    position: relative;
    background: #fff;
    cursor: pointer;
    transition: $transition;

    &:hover .timeline__text {
      color: $blue;
    }

    &:hover::after {
      background: lighten($color: $blue, $amount: 35);
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background: #F3F3F3;
      transition: $transition;
    }
  }

  &__item--active {
    @extend .timeline__item;
    border: 2.5px solid $blue;
    pointer-events: none;
    box-shadow: 0px 0px 3px 3px lighten($color: $blue, $amount: 35) !important;

    &::after {
      background: $blue;
      box-shadow: 0px 0px 3px 3px lighten($color: $blue, $amount: 30) !important;

    }

    .timeline__text {
      color: $blue;
    }
  }


  &__text {
    position: absolute;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -100%);
    min-width: 163px;
    font-weight: 400;
    padding-bottom: 15px;
    color: #C8C8C8;
    transition: $transition;
  }
}

@screen lg {
  .switcherShadow {
    box-shadow: 0px 4px 12px 0px #0E2A381A;
  }
}

.customShadow {
  box-shadow: 0px 4px 12px 0px #0E2A381A;
}

.customShadow--big {
  box-shadow: 0px 12px 64px 0px #11111114;
}


.smallWidthContainer {
  width: 100%;
  max-width: 1100px;
}

// ANIMATED INFO RECTANGLES
.animatedInfo {
  &__cards {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }

  &__card {
    position: relative;
    overflow: hidden;
    padding: 20px;
    flex: 1;
    min-width: 270px;
    height: 220px;
    cursor: pointer;
    box-shadow: 0px 0px 12px 0px #0E2A381A;
    border-radius: 6px;
    transition: $transition;
    border: 1px solid transparent;

    &:hover {
      background: #eff7fcb7;
      box-shadow: 0px 0px 12px 0px #449bc651;
      border: 1px solid lighten($color: $blue, $amount: 25);
    }

    &:hover .animatedInfo__cardTitle {
      bottom: -10%;
      opacity: 0;
    }

    &:hover .animatedInfo__cardText {
      bottom: 10%;
      opacity: 1;
    }
  }
  
  &__card--big {
    @extend .animatedInfo__card;
    min-width: 40% !important;
  }

  &__arrow {
    position: absolute;
    right: 20px;
    width: 30px;
    bottom: 10%;
  }

  &__cardTitle {
    position: absolute;
    left: 0;
    width: 85%;
    bottom: 10%;
    opacity: 1;
    padding: 0 20px;
    font-weight: 400;
    transition: bottom 0.4s ease, opacity 0.3s ease;
  }

  &__cardText {
    position: absolute;
    left: 0;
    bottom: 20%;
    opacity: 0;
    padding: 0 20px;
    font-size: 15px;
    width: 85%;
    transition: bottom 0.5s ease, opacity 0.3s ease;
  }
}

// SPEAKER
// .blueShadow {
//   box-shadow: 0px 0px 5px 3px lighten($color: $blue, $amount: 20) !important;

//   &:hover {
//     box-shadow: 0px 0px 5px 3px lighten($color: $blue, $amount: 25) !important;
//   }
// }


// BLUKITE LOADER VIDEO

.loaderWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  // animation: fadeOut 0.5s 4s ease-in-out forwards;
  animation: fadeOut 2s 2.5s ease-in-out forwards;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 800px;
  width: 80%;
  transform: translate(-50%, -50%);
}

.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}


@keyframes slideLeft {
  from {
    opacity: 0;
    transform: translateX(-3%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}


@media (min-width: 1500px) {
  .animatedInfo {

    &__card {
      min-width: 330px;
    }
  }
}


@media (max-width: 1500px) {
  .animatedInfo {

    &__card {
      height: 260px;
    }

    &__card--big {
      height: 220px;
    }
  }
}


@media (max-width: 1400px) {
  .timeline {
    width: 90%;
  }
}

@media (max-width: 1280px) {
  .timeline {
    flex-direction: column;
    width: min-content;
    margin-left: 100px;
    height: 500px;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateY(0px);
      transform: translateX(-50%);
      height: 100%;
      width: 3px;
      border-radius: 5px;
      background: #F3F3F3;
    }

    &__text {
      transform: translate(-100%, -50%);
      top: 50%;
      padding-bottom: 0;
      padding-right: 50px;
      text-align: right;
      min-width: 210px;
    }
  }
}


@media (max-width: 1100px) {
  .animatedInfo {
    &__card {
      height: 220px;
    }
  }
  .policiesPage {
  .sidebar {
    max-width: 100%;
    top: 40px;
    padding: 30px 0 0;
  }
  }
  
  .sidebar {
    max-width: 100%;
  }
}


@media (max-width: 1023px) {
  .policiesPage {
    .sidebar {
    height: auto;
    }
  }
}


@media (max-width: 980px) {
  .animatedInfo {
    &__card {
      min-width: 350px !important;
    }
  }
}


@media (max-width: 820px) {
  .sidebar {
    min-width: auto;
  }

  .infoPage {
    .pageTitle {
      font-size: 28px;
      line-height: normal;
    }

    .subtitle {
      font-size: 20px;
      margin-top: 20px;
    }
  }

  .policiesPage {
    .pageTitle {
      font-size: 28px;
    }
  }
}

@media (max-width: 768px) {
  .sidebar {
    top: 50px;
    padding: 30px 0 0;
  }

  .animatedInfo {

    &__card {
      min-width: 300px;
      height: auto;
      display: flex;
      flex-direction: column;

      &:hover .animatedInfo__cardTitle {
        bottom: auto;
        opacity: 1;
      }

      &:hover .animatedInfo__cardText {
        bottom: auto;
      }
    }

    &__cardTitle {
      position: relative;
      bottom: auto;
      padding: 0;
      margin-top: auto;
      margin-top: 15px;

    }

    &__cardText {
      position: relative;
      opacity: 1;
      bottom: auto;
      padding: 0
    }

  }
}

@media (max-width: 640px) {
  .sidebar {
    top: 50px;
    padding: 10px 0 0 0;
    box-shadow: rgba(255, 255, 255, 1) -10px 10px 10px;
  }

  .pageTitle {
    font-size: 22px;
  }

  .policiesPage {
    .pageTitle {
      font-size: 22px;
      line-height: 24px;
    }
  }

  .popup {

    .ant-form-item {
      margin-bottom: 5px;
    }

    .ant-form-item-label {
      padding-bottom: 3px;
    }
  }
  
  .bookConsultationBtn {
    bottom: 0px ;
  }
}