@import '../../variables.scss';

.solutions {
    // &__bgWrapper {
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     overflow: hidden;
    //     z-index: 1;

    //     img {
    //         width: 100%;
    //     }
    // }

    &__contentWrapper {
        background-image: url('../../../public/images/skyscrapers.jpg');
        color: #ffffff;
    }
    
    &__content {
        width: 90%;
        max-width: 700px;  
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
    }

    &__itemsWrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

// .team {
//     &__member {
//         text-align: center;
//         cursor: pointer;
//         flex: none;
        
//         img {
//             border-radius: 50%;
//             border: 6px solid $blue;
//             background: #ccc; 
//             object-fit: cover;
//             width: 150px; 
//             height: 150px; 
//         }
//     }
// }

.quote {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-auto-rows: min-content;

    &__imgWrapper {
        overflow: hidden;
        // max-height: 580px;
        height: 100%;


        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}

// .rs-container {
//     button {
//         width: 100px;
//     }
// }

@media (max-width: 640px) {
    .solutions {
        &__contentWrapper {
            background-image: url('../../../public/images/skyscrapers-dark.jpg');
            color: #ffffff;
        }
    }
    .team {
        &__member {
            // text-align: center;
            // cursor: pointer;
            // flex: none;
            
            img {
                // border-radius: 50%;
                // border: 6px solid $blue;
                // background: #ccc; 
                // object-fit: cover;
                width: 120px; 
                height: 120px; 
            }
        }
    }
}

.homePage {
    &__banner {
        height: 700px;
        overflow: hidden;
        width: 100%;
    }
}