@import '../../variables.scss';

.ant-select-selector {
    background: white !important;
}

.coreSolutions {

    &__keyServicesNavItems {
        display: flex;
        flex-wrap: wrap;
        font-weight: 400;
        align-items: flex-end;
        // justify-content: space-between;
    }

    &__keyServicesNavItem {
        transition: $transition;
        cursor: pointer;
        padding: 8px 20px;
        border-bottom: 1.5px solid transparent;
        text-transform: uppercase;
        max-width: 300px;
        min-width: 150px;
        flex: 1;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;

        &:hover {
            color: $blue;
        }
    }

    &__keyServicesNavItem--active {
        @extend .coreSolutions__keyServicesNavItem;
        @apply bg-lightBlue text-blue;
        border-bottom: 1.5px solid $blue;

    }

    &__keyServicesItem {
        // max-width: 500px;
    }

    &__keyServicesItemImage {
        width: 450px !important;
        margin-left: 50px;
    }


    &__keyServicesItemImage--small {
        @extend .coreSolutions__keyServicesItemImage;
        width: 350px !important;
    }


    // FEATURES
    &__keyFeaturesItems {
        display: grid;
        gap: 30px;
        grid-auto-flow: dense;
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        justify-content: center;

        &>li {
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0px 2px 12px 0px #0E2A381A;
            padding: 20px;
            border-radius: 6px;
            border: 1px solid transparent;
            cursor: pointer;
            transition: $transition;
            text-align: center;
            height: 270px;

            &:hover {
                border: 1px solid lighten($color: $blue, $amount: 28)
            }
        }
    }

    &__keyFeaturesReadMoreArrow {
        transition: .3s ease-in-out;
        transform: rotate(180deg);
    }

    &__keyFeaturesItem--fullwidth {
        grid-column: 1 / -1;
        box-shadow: 0px 2px 12px 0px #0E2A381A;
        padding: 20px;
        border-radius: 6px;
        box-shadow: 0px 2px 12px 5px lighten($color: $blue, $amount: 37) !important;
        animation: slideIn .3s ease-in-out;
        overflow: hidden;
        animation-fill-mode: forwards;
    }

    &__keyFeaturesItem--active {
        box-shadow: 0px 2px 12px 5px lighten($color: $blue, $amount: 37) !important;
        border: 1px solid lighten($color: $blue, $amount: 25) !important;

        .coreSolutions__keyFeaturesReadMoreArrow {
            transform: rotate(0);
        }

    }

    // &__keyFeaturesItem--activeExpanded {
    // box-shadow: 0px 0px 12px 5px lighten($color: $blue, $amount: 35) !important;
    // }
}

.customerMonitoring {
    &__cards {
        display: grid;
        gap: 40px;
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));

        &>div {
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0px 2px 12px 0px #0E2A381A;
            padding: 30px 20px;
            border-radius: 6px;
            border: 1px solid transparent;
            cursor: pointer;
            transition: $transition;
            text-align: center;
        }
    }

    &__offers {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    &__offer {
        display: flex;
        gap: 50px;
        align-items: center;
        margin: 30px 0;
    }

    &__offerItem {
        width: 400px;
    }

    &__offerImgWrapper {
        width: 400px;
        display: flex;
    }

    &__offerDashedLine {
        position: absolute;
        top: 50px;
        left: 50%;
        height: 85%;
        transform: translateX(-50%) !important;
        z-index: -1;

        img {
            height: 100%;
        }
    }

    &__offerNumber {
        border-radius: 50%;
        padding: 10px;
        color: rgba($color: $blue, $alpha: .5);
        background: #fff;
        box-shadow: 0px 6px 22px 0px #11111114;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        font-size: 32px;
        font-weight: 600;
    }
}



@keyframes slideIn {
    0% {
        height: 0%;
        opacity: 0;
    }

    100% {
        height: 100%;
        opacity: 1;
    }
}

@media (max-width: 1300px) {
    .customerMonitoring {
        &__offerItem {
            width: 250px;
        }

        &__offerImgWrapper {
            width: 250px;
        }
    }
}


@media (max-width: 1023px) {
    .customerMonitoring {
        &__offer {
            gap: 20px;
            flex-direction: column;
        }

        &__offerItem {
            width: 100%;
        }

        &__offerImgWrapper {
            width: 100%;
            // border: 1px solid red;
            justify-content: flex-start;
        }

        &__offerNumber {
            display: none;
        }
    }
}

@media (max-width: 1100px) {
    .coreSolutions {
        &__keyServicesItemImage {
            width: 50% !important;
        }
    }
}

@media (max-width: 1024px) {
    .coreSolutions {
        &__keyServicesItemImage {
            width: 70% !important;
            margin: 0 auto;
            margin-top: 30px;
        }

        &__keyServicesItemImage--small {
            width: 50% !important;
        }


        &__keyServicesItem {
            max-width: 100%;
        }
    }
}


@media (max-width: 640px) {
    .coreSolutions {
        &__keyServicesItemImage {
            width: 100% !important;
        }

        &__keyFeaturesItems {
            gap: 20px;

            &>li {
                height: 270px;
            }
        }
    }

    .customerMonitoring {
        &__offerImgWrapper {
            width: 60%;
        }

        &__offerItem {
            p {

                text-align: left !important;
            }
        }

    }
}